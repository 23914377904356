import React, { useState, useEffect, useRef } from "react";
import ContactInformationCheck from "../components/ContactInformationCheck";
import LeadTypesChooser from "../components/LeadTypesChooser";
import clsx from "clsx";
import Axios from "axios";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import Checkbox from "../components/Checkbox";
import GaugeChart from "react-gauge-chart";
import { Trans, useTranslation } from "react-i18next";
import { KEYS } from "../util/keys";
import { LinkToPartnerAttorneys } from "../components/ImportantMessage";

const preferredTimes = [
	"Call ASAP",
	"9am – 10am",
	"11am – 12pm",
	"1pm – 2pm",
	"3pm – 4pm",
	"4pm – 5pm",
	"Call me after 5 pm"
];

const scoreTitles = [
	"Low Case Value Determined!",
	"Medium Case Value Determined!",
	"High Case Value Determined!",
	"Highest Case Value Determined!"
];

const scoreDegrees = [
	"Low degree",
	"Medium degree",
	"High degree",
	"Highest degree"
];

const scorePercents = [0.12, 0.36, 0.75, 0.95];

export default function ColdConvert() {
	const steps = 3;
	const [step, setStep] = useState(0); //0
	const [ajax, setAjax] = useState(false);
	const [userData, setUserData] = useState({});
	const [formData, setFormData] = useState({
		injuries: 1,
		commercial: undefined,
		fault: undefined,
		earnings: 1,
		medical: 1,
		pain: 1
	});
	const [legalConsult, setLegalConsult] = useState(undefined);
	const resultIds = useRef();
	const leadTypesRef = useRef({});
	const [leadTypes, setLeadTypes] = useState({ notInterested: true });
	const [preferredTime, setPreferredTime] = useState("Call ASAP");
	const [mobileHover, setMobileHover] = useState(null);
	const [tcpaText, setTcpaText] = useState(null);
	const { t, i18n } = useTranslation();
	const history = useHistory();

	// useEffect(() => {
	// 	const STEPtoURL = {
	// 		0: "info-screen",
	// 		1: "accident-details",
	// 		2: "user-info",
	// 		2.5: "phone-confirm",
	// 		3.5: "additional-info",
	// 		4.5: "submit",
	// 		5: "success"
	// 	};
	// 	if (STEPtoURL[step]) {
	// 		// console.log(step, STEPtoURL[step]);
	// 		gtag("event", "page_view", {
	// 			page_title: "Find My Report",
	// 			page_location: "/find-my-report/" + STEPtoURL[step],
	// 			page_path: "/find-my-report/" + STEPtoURL[step]
	// 		});
	// 	}
	// }, [step]);

	const complete = () => {
		// console.log(userData);
		// console.log(formData);

		setAjax(true);
		if (
			!Object.keys(leadTypesRef.current).length ||
			leadTypesRef.current.notInterested
		)
			leadTypesRef.current = { CALawyer: "AM" };

		let q = new URLSearchParams(window.location.search);
		q = Object.fromEntries(q.entries());

		const businessUserToken =
			localStorage.getItem(KEYS.KEY_BUSINESS_USER_TOKEN) ?? undefined;

		Axios.post("/leads/create/calc", {
			firstName: userData.name.split(" ")[0],
			lastName: userData.name.split(" ")[1],
			phone: userData.phone,
			email: userData.email,
			legal_consult: legalConsult,
			injured: formData.injuries > 0,
			leadTypes: leadTypesRef.current,
			formData,
			calc_id: q.id,
			language: i18n.language,
			trusted_form_cert: window.cert?.xxTrustedFormCertUrl || "",
			gclidcrm: window.gclidcrm || "",
			businessUserToken
		}).then((d) => {
			setAjax(false);
			if (d.data.resultIds) resultIds.current = d.data.resultIds;

			setStep(5);

			// gtag("event", "find-success", {
			// 	event_category: "find",
			// 	event_label: legalConsult ? "find-success-hot" : "find-success-cold"
			// });

			// if (legalConsult || leadTypesRef.current.hasOwnProperty("__CALawyer")) {
			// 	gtag("event", "CALawyer HOT", {
			// 		event_category: "find",
			// 		event_label: "CALawyer HOT"
			// 	});
			// }
		});
	};

	const updatePreferredTime = async () => {
		// console.log(resultIds.current, preferredTime);
		try {
			await Axios.put("leads/update/time", {
				ids: resultIds.current,
				preferredCallTime: preferredTime
			});
			Swal.fire(
				t("THANK_YOU"),
				t("YOUR_PREFERRED_TIME_FOR_CALL_HAS_BEEN_UPDATED"),
				"success"
			).then(() => {
				window.location.href = "/";
			});
		} catch (e) {
			console.log(e);
		}
	};

	// injuries: 1,
	// commercial: undefined,
	// fault: undefined,
	// earnings: 1,
	// medical: 1,
	// pain: 1

	const calcScore = (data) => {
		if (data.fault === true) return 0;
		else {
			if (
				data.injuries === 2 ||
				data.earnings === 2 ||
				data.medical === 2 ||
				data.pain === 2 ||
				data.commercial
			)
				return 3;
			else {
				if (
					data.injuries === 1 ||
					data.earnings === 1 ||
					data.medical === 1 ||
					data.pain === 1
				)
					return 2;
				else {
					return 1;
				}
			}
		}
		// else if (data.fault === false) return 2;
		// else return 1;
	};

	const toggleMobileHover = (w) => {
		if (w === mobileHover) setMobileHover(null);
		else setMobileHover(w);
	};

	return (
		<div
			id="notify-me"
			className={ajax ? "steps-container ajax" : "steps-container"}
		>
			{/* {step} */}
			{step === 0 ? (
				<div
					className={step >= 0 ? "steps-page first show" : "steps-page first"}
					data-testid="step0"
				>
					<a className="close" onClick={() => history.push("/")}>
						<img src="/assets/icon-close.png" alt="close" />
					</a>
					<br className="hide-mobile" />
					<br className="hide-mobile" />
					<br className="hide-mobile" />
					<br className="hide-mobile" />
					<br className="hide-mobile" />
					<br />
					<div className="row">
						<div>
							<img
								src="/assets/cold-convert/hero.png"
								style={{ width: "100%" }}
							/>
						</div>
						<div className="intro">
							<h1>
								<Trans i18nKey={"CALCULATE_YOUR_ACCIDENT"} />
							</h1>
							<div className="line" />
							<p>{t("PROVIDE_SOME_BASIC_INFORMATION2")}</p>
							<button
								onClick={() => setStep(1)}
								type="button"
								data-testid="step1-continue"
							>
								{t("CONTINUE")}
							</button>
							<br />
							<small>
								<Trans i18nKey={"KEEPING_MYACCIDENT_ORG_FREE"} />{" "}
								<a href="https://myaccident.org/terms-of-use" target="_blank">
									{t("TERMS_OF_USE")}.
								</a>
							</small>
						</div>
					</div>
				</div>
			) : (
				<>
					{/* {step} */}
					<div className="steps">
						<div className={step >= 1 ? "step active" : "step"}>
							<strong>{t("STEP_1")}</strong>
							{t("ACCIDENT_INFORMATION")}
						</div>
						<div className={step > 2 ? "step active" : "step"}>
							<strong>{t("STEP_2")}</strong>
							{t("CONTACT_INFORMATION")}
						</div>
						{/* <div className={step > 3 ? "step active" : "step"}>
							<strong>Step 3</strong>
							Additional info
						</div> */}
						<div className={step >= 3 ? "step active" : "step"}>
							<strong>{t("STEP_3")}</strong>
							{t("VIEW_RESULTS")}
						</div>
					</div>
					<div className="steps-progress">
						<div
							className="bar"
							style={{ width: ((step - 1) / steps) * 100 + "%" }}
						/>
					</div>
				</>
			)}

			<div
				className={step >= 1 ? "steps-page show" : "steps-page"}
				data-testid="step1"
			>
				<h1>{t("HELP_US_CALCULATE_YOUR_ACCIDENT_CLAIM_VALUE")}</h1>
				<br />
				<p>
					<Trans i18nKey={"FIND_OUT_IF_YOU_QUALIFY"} />
					<strong className="orange-back">
						{t("COMPLETE_THE_FOLLOWING_QUESTIONS_")}
					</strong>
				</p>

				<div id="cold-convert-questions">
					<section>
						<div className="left">
							<div>
								<h3>{t("WERE_YOU_AT_FAULT_FOR_THIS_ACCIDENT_")}</h3>
								<div className="row yes-no">
									<div
										className={formData.fault === false ? "checked" : ""}
										onClick={() =>
											setFormData({
												...formData,
												...{ fault: false }
											})
										}
									>
										{t("NO")}
										<div className="yes-no-box">
											<img
												src="/assets/checked.svg"
												style={{ marginRight: 0 }}
											/>
										</div>
									</div>
									<div
										className={formData.fault ? "checked" : ""}
										onClick={() =>
											setFormData({
												...formData,
												...{ fault: true }
											})
										}
									>
										{t("YES")}
										<div className="yes-no-box">
											<img
												src="/assets/checked.svg"
												style={{ marginRight: 0 }}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							className={mobileHover === 1 ? "right hover" : "right"}
							onClick={() => toggleMobileHover(1)}
						>
							<img src="/assets/cold-convert/fault.png" />
							<span>i</span>
							<div onClick={() => setMobileHover(null)}>
								<h4>{t("AT_FAULT_STATUS")} ($)</h4>
								{t("FAULT_STATUS_IS")}
							</div>
						</div>
					</section>

					<section>
						<div className="left">
							<div>
								<h3>{t("WAS_THERE_A_COMMERCIAL_VEHICLE_INVOLVED_")}</h3>
								<div className="row yes-no">
									<div
										className={formData.commercial === false ? "checked" : ""}
										onClick={() =>
											setFormData({
												...formData,
												...{ commercial: false }
											})
										}
									>
										{t("NO")}
										<div className="yes-no-box">
											<img
												src="/assets/checked.svg"
												style={{ marginRight: 0 }}
											/>
										</div>
									</div>
									<div
										className={formData.commercial ? "checked" : ""}
										onClick={() =>
											setFormData({
												...formData,
												...{ commercial: true }
											})
										}
									>
										{t("YES")}
										<div className="yes-no-box">
											<img
												src="/assets/checked.svg"
												style={{ marginRight: 0 }}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							className={mobileHover === 2 ? "right hover" : "right"}
							onClick={() => toggleMobileHover(2)}
						>
							<img src="/assets/cold-convert/commercial.png" />
							<span>i</span>
							<div onClick={() => setMobileHover(null)}>
								<h4>{t("COMMERCIAL_VEHICLE_INVOLVEMENT")} ($)</h4>
								{t("IF_YOUR_ACCIDENT_INVOLVED_A_COMMERCIAL_VEHICLE")}
							</div>
						</div>
					</section>
					<section>
						<div className="left">
							<h3>
								{t("WHAT_DEGREE_OF_INJURIES_DID_YOU_SUSTAIN_IN_YOUR_ACCIDENT_")}
							</h3>
							<input
								type="range"
								min={0}
								max={2}
								step={1}
								list="ticks"
								value={formData.injuries}
								onChange={(e) =>
									setFormData({
										...formData,
										...{ injuries: Number(e.target.value) }
									})
								}
							/>
							<datalist id="ticks" style={{ "--list-length": 3 }}>
								<option value="0">{t("LOW")}</option>
								<option value="1">{t("MEDIUM")}</option>
								<option value="2">{t("HIGH")}</option>
							</datalist>
						</div>
						<div
							className={mobileHover === 3 ? "right hover" : "right"}
							onClick={() => toggleMobileHover(3)}
						>
							<img src="/assets/cold-convert/injuries.png" />
							<span>i</span>
							<div onClick={() => setMobileHover(null)}>
								<h4>{t("INJURIES_AND_MEDICAL_EXPENSES")} ($)</h4>
								{t("WHY_IS_INJURY_IMPORTANT")}
							</div>
						</div>
					</section>
				</div>
				<div
					className="buttons"
					style={{ maxWidth: 700, margin: "50px auto 0" }}
				>
					<button onClick={() => setStep(0)} className="white" type="button">
						{t("BACK")}
					</button>

					<button
						className="green"
						onClick={() => setStep(1.75)}
						disabled={
							formData.fault === undefined || formData.commercial === undefined
						}
					>
						{t("NEXT")}
					</button>
				</div>
				<p style={{ textAlign: "center", marginTop: 40 }}>
					<img src="/assets/ssl.svg" width="150" />
				</p>
			</div>
			<div
				className={step >= 1.5 ? "steps-page show" : "steps-page"}
				data-testid="step15"
			>
				<h1>{t("HELP_US_CALCULATE_YOUR_ACCIDENT_CLAIM_VALUE")}</h1>
				<br />
				<p>
					<Trans i18nKey={"FIND_OUT_IF_YOU_QUALIFY"} />
					<strong className="orange-back">
						{t("COMPLETE_THE_FOLLOWING_QUESTIONS_")}
					</strong>
				</p>

				<div id="cold-convert-questions">
					<section>
						<div className="left">
							<h3>{t("DID_YOU_EXPERIENCE_A_LOSS_OF_INCOME_")}</h3>
							<input
								type="range"
								min={0}
								max={2}
								step={1}
								list="ticks"
								value={formData.earnings}
								onChange={(e) =>
									setFormData({
										...formData,
										...{ earnings: Number(e.target.value) }
									})
								}
							/>
							<datalist id="ticks" style={{ "--list-length": 3 }}>
								<option value="0">{t("LOW")}</option>
								<option value="1">{t("MEDIUM")}</option>
								<option value="2">{t("HIGH")}</option>
							</datalist>
						</div>
						<div
							className={mobileHover === 4 ? "right hover" : "right"}
							onClick={() => toggleMobileHover(4)}
						>
							<img src="/assets/cold-convert/earnings.png" />
							<span>i</span>
							<div onClick={() => setMobileHover(null)}>
								<h4>{t("LOST_EARNINGS")} ($)</h4>
								{t("ACCIDENTS_CAN_COMMONLY_LEAD_TO_A_LOSS_OF_EARNINGS")}
							</div>
						</div>
					</section>

					<section>
						<div className="left">
							<h3>{t("DO_YOU_EXPECT_FUTURE_MEDICAL_EXPENSES_")}</h3>
							<input
								type="range"
								min={0}
								max={2}
								step={1}
								list="ticks"
								value={formData.medical}
								onChange={(e) =>
									setFormData({
										...formData,
										...{ medical: Number(e.target.value) }
									})
								}
							/>
							<datalist id="ticks" style={{ "--list-length": 3 }}>
								<option value="0">{t("LOW")}</option>
								<option value="1">{t("MEDIUM")}</option>
								<option value="2">{t("HIGH")}</option>
							</datalist>
						</div>
						<div
							className={mobileHover === 5 ? "right hover" : "right"}
							onClick={() => toggleMobileHover(5)}
						>
							<img src="/assets/cold-convert/medical.png" />
							<span>i</span>
							<div onClick={() => setMobileHover(null)}>
								<h4>{t("ESTIMATED_FUTURE_MEDICAL_EXPENSES")}</h4>
								{t("IF_YOUR_INJURIES_WILL_REQUIRE_ONGOING_MEDICAL_TREATMENT")}
							</div>
						</div>
					</section>

					<section>
						<div className="left">
							<h3>{t("DID_YOU_EXPERIENCE_PAIN_AND_SUFFERING_")}</h3>
							<input
								type="range"
								min={0}
								max={2}
								step={1}
								list="ticks"
								value={formData.pain}
								onChange={(e) =>
									setFormData({
										...formData,
										...{ pain: Number(e.target.value) }
									})
								}
							/>
							<datalist id="ticks" style={{ "--list-length": 3 }}>
								<option value="0">{t("LOW")}</option>
								<option value="1">{t("MEDIUM")}</option>
								<option value="2">{t("HIGH")}</option>
							</datalist>
						</div>
						<div
							className={mobileHover === 6 ? "right hover" : "right"}
							onClick={() => toggleMobileHover(6)}
						>
							<img src="/assets/cold-convert/pain.png" />
							<span>i</span>
							<div onClick={() => setMobileHover(null)}>
								<h4>{t("MULTIPLIER_FOR_GENERAL_DAMAGES")}</h4>
								{t(
									"THERE_IS_A_MULTIPLIER_USED_TO_ESTIMATE_YOUR_GENERAL_DAMAGES"
								)}
								<ul>
									<li>{t("PAIN_AND_SUFFERING")}</li>
									<li>{t("LOSS_OF_ENJOYMENT_OF_LIFE")}</li>
									<li>{t("EMOTIONAL_DISTRESS")}</li>
									<li>{t("LOSS_OF_CONSORTIUM__COMPANIONSHIP_")}</li>
								</ul>
							</div>
						</div>
					</section>
				</div>
				<p style={{ marginTop: 30 }}>
					<button
						className="green"
						style={{ margin: "auto" }}
						onClick={() => setStep(2)}
					>
						{t("CALCULATE_MY_CLAIM_NOW_")}
					</button>
				</p>
				<p style={{ textAlign: "center", marginTop: 40 }}>
					<img src="/assets/ssl.svg" width="150" />
				</p>
			</div>
			<div
				className={step >= 2 ? "steps-page show" : "steps-page"}
				data-testid="step2"
			>
				{step < 3 && (
					<div style={{ maxWidth: 500, margin: "auto" }}>
						<ContactInformationCheck
							back={() => setStep(1)}
							next={(data) => {
								setUserData(data);
								setStep(3);
							}}
							smsSent={() => setStep(2.5)}
							setTcpaText={setTcpaText}
							trustedformPingUrl={window.xxTrustedFormPingUrl}
							funnel="calc"
						/>
					</div>
				)}

				<p style={{ textAlign: "center", marginTop: 60 }}>
					<img src="/assets/ssl.svg" width="150" />
				</p>
			</div>

			<div
				className={step >= 3 ? "steps-page show" : "steps-page"}
				data-testid="step3"
			>
				<h1>{t("YOUR_ACCIDENT_CLAIM_VALUE")}</h1>
				<br />
				<p>
					<Trans i18nKey={"FIND_OUT_IF_YOU_QUALIFY"} />
					{/* <strong className="orange-back">
						Choose from the options below:
					</strong> */}
				</p>
				<br />
				<br />
				<div id="cold-convert-confirm">
					<section>
						<div className="left">
							<h3 style={{ textAlign: "center" }}>
								{t(scoreTitles[calcScore(formData)])}
							</h3>
							<br />
							{/* {scorePercents[calcScore(formData)]} */}
							<GaugeChart
								nrOfLevels={3}
								arcsLength={[0.25, 0.25, 0.5]}
								colors={["#5BE12C", "#F5CD19", "#EA4228"].reverse()}
								percent={scorePercents[calcScore(formData)]}
								arcPadding={0.02}
								arcWidth={0.3}
								cornerRadius={0}
								hideText={true}
							/>
						</div>
						<div className="right">
							<p>{t("YOUR_CASE_VALUE_IS_DETERMINED_BY_")}</p>
							<ul>
								<li>
									{formData.fault ? t("AT_FAULT_PARTY") : t("NOT_AT_FAULT")}
								</li>
								{formData.commercial ? (
									<li>{t("COMMERCIAL_VEHICLE_INVOLVEMENT")}</li>
								) : null}
								<li>
									{t(scoreDegrees[formData.medical])} {t("OF_MEDICAL_EXPENSES")}
								</li>
								<li>
									{t(scoreDegrees[formData.injuries])} {t("OF_INJURY_STATUS")}
								</li>
								<li>
									{t(scoreDegrees[formData.earnings])} {t("OF_LOSS_OF_INCOME")}
								</li>
								<li>
									{t(scoreDegrees[formData.pain])} {t("OF_PAIN_AND_SUFFERING")}
								</li>
							</ul>
						</div>
					</section>
					<div style={{ height: 1, background: "#DDD", margin: "40px 0" }} />
					{/* <h3>To get a more detailed evaluation, request a call below.</h3>
					<div>
						<br />
						Based on your responses, you qualify for a{" "}
						<strong>free case evaluation</strong> to help determine your{" "}
						<strong>accident claim value</strong>. A five-minute call can tell
						you if you are eligible for a cash settlement.
					</div> */}

					<div
						className="row"
						style={{
							alignItems: "flex-start",
							marginTop: 40,
							marginBottom: 20
						}}
					>
						<div>
							<div className="row" style={{ alignItems: "center" }}>
								<img
									src="/assets/callme.png"
									style={{ width: 100 }}
									className="hide-mobile"
								/>
								<h3 style={{ opacity: 0.7, fontSize: 21 }}>
									{t(
										"REQUEST_A_FREE_LEGAL_CONSULTATION_FOR_A_DETAILED_ESTIMATE"
									)}
								</h3>
							</div>
						</div>
						<div style={{ flex: 1 }}>
							<div className="row yes-no">
								<img
									src="/assets/callme.png"
									style={{
										width: 88,
										position: "relative",
										display: "inline",
										top: 10
									}}
									className="hide-desktop"
								/>
								<div
									onClick={() => {
										setLegalConsult(false);
									}}
									className={legalConsult === false ? "checked" : ""}
									data-testid="no"
								>
									{t("NO")}
									<div className="yes-no-box">
										<img src="/assets/checked.svg" />
									</div>
								</div>
								<div
									onClick={() => {
										setLegalConsult(true);
									}}
									className={legalConsult ? "checked" : ""}
									data-testid="yes"
								>
									{t("YES")}
									<div className="yes-no-box">
										<img src="/assets/checked.svg" />
									</div>
								</div>
								{/* <div
								style={{ minWidth: 120, paddingTop: 25 }}
								onClick={() => {
									setLegalConsult(false);
									setConfirm(true);
									next();
								}}
								data-testid="no"
							>
								<a>No, Thanks</a>
							</div> */}
							</div>
							<br />
							<small style={{ marginLeft: 10, display: "block" }}>
								<Trans i18nKey={"BY_CLICKING_YES"} />
							</small>
						</div>
					</div>
					<div style={{ clear: "both" }} />
					{legalConsult === undefined ? (
						<small>
							{t("BY_SUBMITTING_THIS_REQUEST")}{" "}
							<a href="https://myaccident.org/terms-of-use" target="_blank">
								{t("TERMS_OF_USE")}.
							</a>
						</small>
					) : null}
					{legalConsult === true ? (
						tcpaText ? (
							<span dangerouslySetInnerHTML={{ __html: tcpaText }} />
						) : (
							<small>
								<Trans
									i18nKey={"BY_SUBMITTING_THIS_REQUEST_YOU_CONSENT_8_TO_9"}
									components={{
										link: (
											<LinkToPartnerAttorneys text={t("MARKETING_PARTNERS")} />
										)
									}}
								/>
								<a href="https://myaccident.org/terms-of-use" target="_blank">
									{t("TERMS_OF_USE")}.
								</a>
							</small>
						)
					) : null}
					{legalConsult === false ? (
						<small>
							<Trans
								i18nKey={"KEEPING_MYACCIDENT_ORG_FREE_8_TO_9"}
								components={{
									link: <LinkToPartnerAttorneys text={t("SPONSORS")} />
								}}
							/>{" "}
							<a href="https://myaccident.org/terms-of-use" target="_blank">
								{t("TERMS_OF_USE")}
							</a>
							.
						</small>
					) : null}
				</div>
				<div className="buttons confirm">
					<button
						disabled={legalConsult === undefined}
						className="green"
						onClick={() => {
							legalConsult ? complete() : setStep(3.5);
						}}
					>
						{legalConsult ? "Submit" : t("NEXT")}
					</button>
					<button className="outline" onClick={() => setStep(2)}>
						{t("BACK")}
					</button>
				</div>
			</div>
			<div
				className={step >= 3.5 ? "steps-page show" : "steps-page"}
				data-testid="step35"
			>
				<LeadTypesChooser
					leadTypes={leadTypes}
					onChange={(l) => {
						leadTypesRef.current = l;
						setLeadTypes(l);
					}}
				/>
				<div
					className="buttons"
					style={{ maxWidth: 700, margin: "30px auto " }}
				>
					<button
						onClick={() => {
							setStep(3);
						}}
						className="white"
						type="button"
					>
						{t("BACK")}
					</button>
					<button className="blue" onClick={() => complete()}>
						{t("SUBMIT")}
					</button>
				</div>
			</div>
			<div
				className={step >= 4 ? "steps-page show" : "steps-page"}
				data-testid="step4"
			>
				{legalConsult ? (
					<div style={{ textAlign: "center" }}>
						<img src="/assets/swal-success.png" width="80" />
						<a style={{ position: "absolute", right: 20 }} href="/">
							<img src="/assets/icon-close.png" />
						</a>
						<br />
						<br />
						<h2 style={{ textAlign: "center" }}>
							{t("YOU_RE_ALL_SET", { name: userData.name?.split(" ")[0] })}
						</h2>
						<br />
						<div className="row">
							<div style={{ textAlign: "center" }}>
								{t("OUR_TEAM_WILL_REACH_OUT")}
							</div>
						</div>
						<br />
						<div style={{ height: 1, background: "#CCC" }}></div>
						<br />
						<p style={{ textAlign: "center", color: "var(--orange)" }}>
							<Trans
								i18nKey={
									"YOU_REQUESTED_TO_SPEAK_TO_SOMEONE_ABOUT_YOUR_ACCIDENT"
								}
							/>
						</p>
						<br />
						{preferredTimes.map((v) => (
							<div
								style={{
									maxWidth: i18n.language === "en" ? "170px" : "270px",
									margin: "10px auto",
									cursor: "pointer",
									display: "flex"
								}}
								onClick={() => setPreferredTime(v)}
								key={v}
							>
								<span style={{ marginRight: 8 }}>
									<Checkbox checked={preferredTime === v} />
								</span>
								{t(v)}
							</div>
						))}
						<br />
						<p style={{ textAlign: "center", fontSize: 12 }}>
							{t("MYACCIDENT_ORG_OPERATES_BETWEEN_9_AM_AND_5_PM")}
						</p>
						<br />
						<div style={{ height: 1, background: "#CCC" }}></div>
						<br />
						<div style={{ textAlign: "center" }}>
							<button
								style={{ margin: "auto", minWidth: 250 }}
								className="green"
								disabled={!preferredTime}
								onClick={() => updatePreferredTime()}
							>
								{t("SUBMIT")}
							</button>
						</div>
					</div>
				) : (
					<div style={{ textAlign: "center" }}>
						<img src="/assets/swal-success.png" width="101" />
						<a style={{ position: "absolute", right: 20 }} href="/">
							<img src="/assets/icon-close.png" />
						</a>
						<br />
						<br />
						<h1 style={{ textAlign: "center" }}>
							{t("YOU_RE_ALL_SET", { name: userData.name?.split(" ")[0] })}
						</h1>
						<br />
						<div className="row" style={{ textAlign: "center" }}>
							<div>{t("OUR_TEAM_WILL_REACH_OUT")}</div>
						</div>
						<br />
						<div style={{ height: 1, background: "#CCC" }}></div>
						<br />
						<div style={{ maxWidth: 700, margin: "auto" }}>
							<h1 style={{ textAlign: "center" }}>
								{t("WHEN_DO_I_NEED_A_LAWYER_")}
							</h1>
							<br />
							<p>
								<Trans
									i18nKey={"LEARN_MORE_ABOUT_IF_YOU_NEED_TO_GET_LEGAL_ADVICE"}
								/>
							</p>
							<br />
							<div style={{ textAlign: "center" }}>
								<a
									className="button blue"
									href="https://myaccident.org/request-free-legal-consultation"
									target="_blank"
								>
									{t("LEARN_MORE")}
								</a>
								<br />
								<a className="button outline" href="/">
									{t("CLOSE")}
								</a>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
